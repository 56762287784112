import React from 'react'
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { useFormik } from 'formik';
import { CategorySchema } from '../../Validation';
import { IoMdCloseCircle } from "react-icons/io";
import HttpService from '../../Api';
import { toast } from 'sonner';
import { useLoader } from '../../Store';

export default function Add() {

  const [color, setColor] = useColor("#561ecb");
  const setIsLoading = useLoader(state => state.setIsLoading);
  const navigate = useNavigate();
  const { values, errors, setFieldValue, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      name: "",
      description: "",
      isImage: true,
      status: 1,
      image: null,
      color: "#561ecb",
      slug:""
    },
    enableReinitialize: true,
    validationSchema: CategorySchema,
    onSubmit: (data) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("slug", data.slug);
      formData.append("description", data.description);
      formData.append("status", data.status.toString());
      formData.append("image", data.image!);
      formData.append("color", data.color);
      HttpService({
        method: 'post',
        url: '/admin/category/add',
        body: formData
      }).then((resp) => {
        toast.success(resp?.data?.message);
        setIsLoading(false);
        navigate('/category');
      }).catch((err: any) => {
        toast.error(err?.response?.data?.message)
        setIsLoading(false);
      });
    }
  })

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">

            <div className="card-header">
              <h3 className="card-title font-weight-bolder h5">Add Category</h3>
              <button className='btn btn-info float-right font-weight-bolder' onClick={() => navigate(-1)}><IoArrowBack /> Back</button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className='row'>
                  {/* Category  Name*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="name">Category Name</label>
                      <input type="text" className="form-control" id="name" value={values.name} onChange={handleChange} placeholder="Please Enter Category Name." />
                      {errors.name && touched.name ? <div className='text-danger h6'>{errors.name}</div> : null}
                    </div>
                  </div>

                  {/* Category  Slug*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="slug">Category Slug</label>
                      <input type="text" className="form-control" id="slug" value={values.slug} onChange={handleChange} placeholder="Please Enter Category Slug." />
                      {errors.slug && touched.slug ? <div className='text-danger h6'>{errors.slug}</div> : null}
                    </div>
                  </div>

                  {/* Category  Description*/}
                  <div className='col-12'>
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <textarea rows={3} className="form-control textarea" id="description" value={values.description} onChange={handleChange} placeholder="Please Enter Description." />
                      {errors.description && touched.description ? <div className='text-danger h6'>{errors.description}</div> : null}
                    </div>
                  </div>

                  {/* Category  Status*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select className='form-control' value={values.status} onChange={handleChange} id='status'>
                        <option unselectable="on" hidden>Please select status</option>
                        <option value={1}>Active</option>
                        <option value={0}>Deactive</option>
                      </select>
                      {errors.status && touched.status ? <div className='text-danger h6'>{errors.status}</div> : null}
                    </div>
                  </div>

                  {/* Category  Image*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="image">Image</label>
                      {
                        values?.image ?
                          <div className='position-relative' style={{ width: 150, height: 150 }}>
                            <IoMdCloseCircle className='cursor-pointer h4 m-0 position-absolute text-white font-weight-bolder' onClick={() => setFieldValue('image', null)} style={{ top: '2%', left: '82%' }} />
                            <img alt='media' className='w-100 h-100 object-fit-cover m-0' src={URL.createObjectURL(values?.image)} />
                          </div> :
                          <input accept="image/*" onChange={(e: any) => setFieldValue('image', e.target.files[0])} type="file" readOnly={true} className="form-control" id="image" placeholder="Please Enter Category Name." />
                      }
                      {errors.image && touched.image ? <div className='text-danger h6'>{errors.image}</div> : null}
                    </div>
                  </div>

                  {/* Category  color*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label>Select Color</label>
                      <ColorPicker color={color} onChange={e => {
                        setColor(e);
                        setFieldValue('color', e.hex)
                      }} />
                      {errors.color && touched.color ? <div className='text-danger h6'>{errors.color}</div> : null}
                    </div>
                  </div>
                </div>

              </div>
              <div className="card-footer clearfix">
                <button className='btn btn-primary mr-2' style={{ width: 100 }} type='submit'>Submit</button>
                <button className='btn btn-secondary ml-2' style={{ width: 100 }} type='button' onClick={() => navigate('/category')}>Cancel</button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div >
  )
}
